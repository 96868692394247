.confirm-selection-container{
  position: absolute;
  width: 95%;
  height: 89%;
  background-color: white;
  z-index: 10;
  border-radius: 50px;
  top: 9%;
  animation: slideUp 0.5s ease-in-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.confirm-selection-header-title{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  border-bottom: 1px solid gray;
  padding-top: 5px;
  padding-bottom: 3px;
  z-index: 20;
  height: 14%;
}

.confirm-selection-header-title  h2 {
  margin: 0 0 0 0;
}

.confirm-selection-header-title  h3{
  margin: 0 0 0 0;
}

.confirm-selection-header-title  p{
  margin: 0 0 0 0;
}

.confirm-your-total-details-list{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 58%;
  overflow: scroll;
  z-index: 20;
}


.confirm-your-total-details-list h1{
  margin: 0 0 0 0;
}


.confirm-your-total-details-list h2{
  margin: 0 0 0 0;
}


.confirm-your-total-details-list h3{
  margin: 0 0 0 0;
}


.confirm-your-total-details-list h4{
  margin: 0 0 0 0;
}

.confirm-your-total-details-list p{
  margin: 0 0 0 0;
}


.confirm-select-more-items{
  display: flex;
  justify-content: center;
  justify-content: center;
  width: 100%;
  padding-Top: 12px;
}

.confirm-your-total-details-total{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: 10%;
  border-top: 1px solid gray;
  z-index: 20;
}

.confirm-your-total-buttons{
  width: 100%;
  height: 16%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 50;
}

.confirm-your-total-buttons button{
  width: 85%;
  height: 80%;
  border-radius: 35px;
  margin-bottom: 5%;
  font-size: 200%;
  z-index: 50;
}

.user-list-item-bottom{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.claimeeIconImageConfirm{
  width: 57px;
  height: 57px;
}

@media (max-width: 350px) {

  .claimeeIconImageConfirm{
      width: 50px;
      height: 50px;
  }
  
}
.user-list-bill-item {
  border-bottom: none;
}