.shareGroupText{
    font-size: 21px;
}

.qrCodeParent{
    width: 80%
}





@media (max-height: 700px){
    

    .shareGroupIDText{
        font-size: 23px;
    }

    .shareGroupText{
        font-size: 17px;
    }
}

@media (max-height: 600px){
    .socialMediaIcon{
        width: 77%;
        height: auto;
    }

    .qrCodeParent {
        padding-top: 1.5%;
        padding-bottom: 1.5%;
        margin-top: 3%;
        margin-bottom: 3%;
    }
}

@media (max-height: 550px){
    .socialMediaIcon{
        width: 77%;
        height: auto;
    }

    .qrCodeParent {
        padding-top: 1%;
        padding-bottom: 1%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
}

