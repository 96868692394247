.create-group-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.header{

    height: 8%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid grey;
}

.body{
    
    height: 92%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.page-container{
    height: 100%;
    width: 100%;
}

.progress-bar{
    height: 12%;
    max-height: 70px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progress-bar-1{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar-2{
  width: 50%;
}

.progress-bar-3{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.identityPageContainer{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.identityPageIn{
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
}


.tascPageContainer{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.billItemsConatinerCG{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.identityButton{
    width: 80%;
    height: 150px;
    border-radius: 50px;
    font-size: 220%;
}

.identityButtonTransperent{
  width: 80%;
  height: 150px;
  border-radius: 50px;
  font-size: 220%;
  background-color: white;
  border: 2px solid #1A1C33;
  color: #1A1C33;
}

.identityButtonBottom{
  width: 80%;
  height: 60px;
  font-size: 23px;
}

.identityButtonBottoBack{
  width: 35%;
  height: 50px;
  font-size: 23px;
  background-color: white;
  border: 2px solid #1A1C33;
  color: #1A1C33;
}

.tascChoiceContainer{
  width: 80%;
  height: 45%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.tascChoiceContainer button{
  width: 100px;
  height: 100px;
  margin-left: 7%;
  margin-right: 7%;
  font-size: 30px;
}

.addItemsTertirary{
  background-color: white;
  border: 2px solid #1A1C33;
  color: #1A1C33;
}

@media (max-width: 300px) {
  .tascChoiceContainer button{
    width: 70px;
    height: 70px;
    font-size: 22px;
  }

  h2{
    font-size: 17px;
  }
}

.tascChoiceContainer h2{
  text-align: center;
}

.tascInputsContainer{
  height: 293px;
  margin-right: auto;
  margin-left: auto;
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.tascInputContainer{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmCreation-button{
  font-size: 150%;
  padding: 15px;
}

.tascInputFeild{
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* page trainistions */

.page-transition-next-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  
  .page-transition-next-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page-transition-next-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  
  .page-transition-next-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page-transition-back-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  
  .page-transition-back-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page-transition-back-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  
  .page-transition-back-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
  }

  .page-container {
    position: relative;
  }
  
  .page-transition-next-enter, 
  .page-transition-next-exit, 
  .page-transition-back-enter, 
  .page-transition-back-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  

.page-transition-next-enter, 
.page-transition-next-exit, 
.page-transition-back-enter, 
.page-transition-back-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
  

.billItemsButtonsHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.billItemsButtonsVertical {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.billItemsControlButtons {
  width: 85%;
}

.billItemControlButton, .billItemControlButtonSmall {
  transition: width 0.3s, height 0.3s, font-size 0.3s, border-radius 0.3s;
}

.billItemControlButtonQuater{
  width: 40%;
  height: 130px;
  margin: 0px 8px;
  font-size: 200%;
}

.fourControlButtons{
  width: 100%;
  height: 293px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.billItemControlButton {
  width: 80%;
  height: 150px;
  font-size: 23px;
  border-radius: 50px;
  background-color: #1A1C33;
  font-size: 220%;
}

.billItemControlButtonTrans{
  width: 80%;
  height: 150px;
  font-size: 23px;
  border-radius: 50px;
  background-color: white;
  border: 2px solid #1A1C33;
  color: #1A1C33;
  font-size: 220%;
}

.extraInfoScan{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid grey;
}

.extraInfoScan button{
  border-radius: 15px;
  width: 45px;
  height: 45px;
}

.billItemControlButtonSmall {
  width: 70%;
  height: 70px;
  font-size: 20px;
  border-radius: 50px;
  background-color: white;
  border: 2px solid #1A1C33;
  color: #1A1C33;
}

.OptionsBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
}



.ServiceChargeMiddle{
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  
}

.extraTotalInfo{
  font-size: 13px;
  margin: 0px;
  margin-right: 2.5%;
}

.serviceChargeAndTaxDiv{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 90%;
}

.createGroupButton {
  width: 40%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-box {
  cursor: pointer;
  background-color: white;
  display: inline-block;
  margin: 10px;
  padding: 10px;
  width: 200px;
  border-radius: 25px;
}

@keyframes slideUpMiddle {
  from {
      bottom: -100%;
  }
  to {
      bottom: 32%;
  }
}

.confirmCreation{
  background-color: white;
  border: #1A1C33 solid 1px;
  border-radius: 25px;
  width: 90%;
  height: auto;
  position: absolute;
  z-index: 5;
  animation: slideUpMiddle 0.5s ease-in-out;
}

.confirmCreation-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.confirmCreation-body{
  width: 80%;
  text-align: center;
}

.confirmCreation-header{
  margin-top: 4%;
}

.confirmCreation-footer{
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.confirmCreation-billTotal{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.userDetails {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 37%;
  width: 80%;
  z-index: 1;
}



.create-room-bill-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 63%;
  background-color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  overflow: hidden;
  z-index: 0;
}

.bill-Items-Title-Section{
  border-bottom: 1px solid gray;
  height: 8%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.bill-Items-Content-Section{
  height: 75%;
  width: 100%;
  overflow-y: scroll;
  
}

.bill-Items-Information-Section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  height: 18%;
  max-height: 110px;
  width: 100%;
}


.create-bill-Items-Title-Section{
  border-bottom: 1px solid gray;
  height: 10%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.create-bill-Items-Content-Section{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  
}

.addItemsCreatGroup{
  width: 85%;
  height: 293px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.tip-Section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
}

.create-bill-Items-Information-Section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
}

.servicechargeAndTaxBillItem{
  margin: 0px;
  margin-bottom: 1.2%;
  font-size: 15px;
}


.provsionalItemsButtons{
  height: 120%;
  width: auto;
}

.billImageCropper{
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 3;
}

.billImageCropperInstructions{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.billImageCropperTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85%;
  height: 13%;
  text-align: center;
  border-bottom: gray solid 1px;
}


.billImageCropperImg{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
  height: 77%;
}

.billImageCropperButtons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 10%;
  border-top: 1px solid gray;
  width: 85%;

}

.billImageCropperEditItemName{
  width: 87%;
}

.billImageCropperEditItemQuantity{
  width: 18%;
  text-align: right;
}

.billImageCropperEditItemTotalPrice{
  width: 40%;
  text-align: right;
}

.tascInputFeild h2{
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

@media (max-height : 500px) {
  .tascInputFeild h2{
    margin: 0;
    margin-bottom: 4px;
    font-size: 16px;
  }

  .tascChoiceContainer button{
    width: 70px;
    height: 70px;
    font-size: 22px;
  }

  .tascChoiceContainer h2{
    margin-bottom: 3px;
  }
}

@media (max-width: 330px) {
  .tascInputFeild h2{
    margin: 0;
    margin-bottom: 4px;
    font-size: 16px;
  }

  .tascChoiceContainer button{
    width: 70px;
    height: 70px;
    font-size: 22px;
  }

  .tascChoiceContainer h2{
    margin-bottom: 3px;
  }
}
