.whiteBackground {
    background-color: white;
  }

.mainLogo{
    z-index: 1;
    width: 85%;
    height: auto;

}



.homeSteps{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
    flex-direction: column;
}

.homeSteps p{
    margin: 0;
    margin-bottom: 4px;
    font-weight: 500;
}



.homeContent{
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 95%;
}

.homeButtonDiv{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    width: 75%;
}

.homeButtonDivBottom{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row; 
    width: 75%;
}

.homeButton{
    border: none;
    padding: 0%;
    border-radius: 100px;
    font-size: 25px;
    width: 100%;
    cursor: pointer;
}

.homeButton2{
    border: none;
    padding: 0%;
    border-radius: 100px;
    font-size: 25px;
    width: 48%;
    cursor: pointer;
}


.bottomRowText{
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 22px;
}


    
@media (max-height: 500px) {
    /* .mainLogo{
        z-index: 1;
        width: 40%;
        height: auto;
    
    } */

    .homeButtonDivBottom{
        margin-bottom: 2%;
    }
    

    .homeButton p{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-height: 580px) {


    .homeButton {
        font-size: 20px;
    }
}


@media (min-aspect-ratio: 4/5) {
    /* .mainLogo{
        z-index: 1;
        width: 50%;
        height: auto;
    } */

    .homeButtonDivBottom{
        margin-bottom: 2%;
    }
    
    .homeButton p{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
