.user-list-bill-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid gray;
}

.user-list-bill-item-horizantal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.item-Display-Group {
  width: 82%;
  border-bottom: 1px solid gray;
  overflow: hidden;
  transition: height 1s;
}

.itemContainer {
  border-radius: 15px;
  padding-bottom: 5%;
  margin: 1%;
  padding-right: 1%;
  padding-left: 1%;
  transition: background-color 0.3s linear;
}


.animatedItemDiv {
  padding-bottom: 1px;
}

.animatedItemDivVerb {
  padding-bottom: 1px;
}


.billItem-Name {
  width: 70%;
  overflow: hidden;
}

.billItem-Name-collapsed {
  text-overflow: ellipsis;
  overflow: hidden;
}

.billItem-Price {
  width: 30%;
  overflow: hidden;
  text-align: right;
}


.calimeesUnexpanded {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 100%;
}



.calimeesExpanded {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.claimeeIconExpanded {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.claimeesULExpanded {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0px;
}

.unclaimedIndicator {
  margin: 0px;
  color: white;
  background-color: lightcoral;
  border-radius: 25px;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
}
