.cam_container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 10;
}

.cam {
    position: absolute;
    width: 100%;
    height: 100%;
}

.cutout {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
    border-radius: 50%;
    background-color: transparent;
  }

.cam_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85%;
    background: radial-gradient(circle at 50% 57%, transparent 100px, rgba(0, 0, 0, 0.7) 100px);
}


.cam_overlay_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cam_button {
    color: white;
    font-size: 20px;
    margin: 0 10px;
}

.cam_button_cap {
    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.cam_guide {
    flex: 1;
    background: rgba(0, 0, 0, 0.5);
  }

.cam_container {
    display: flex;
    align-items: stretch;
}

.cam {
    flex: 8;
}
  
