.bill-Items-Title-Section {
    border-bottom: 1px solid gray;
    height: 8%;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5px;
}

.bill-Items-Content-Section {
    height: 74%;
    width: 100%;
    overflow-y: scroll;

}



.bill-Items-Information-Text {
    margin: 0;
    margin-top: 1%;
}

.bill-Items-Your-Total-Information-Text {
    margin: 0;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 150%;
}

.div-ForLine {
    margin-top: 0.5px;
    border-top: 1px solid gray;
    width: 90%;
}

.shareGroupTitle {
    height: 5%;
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.shareRoomContent {
    height: 95%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: column;
    overflow: 'scroll'
}

.shareGroupBox {
    position: absolute;
    width: 95%;
    height: 97%;
    border-radius: 50px;
    background-color: white;
    border: #1A1C33 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 1%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 100;
}

.shareGroupBox.visible {
    opacity: 1;
}


.joinGroupBox {
    z-index: 1;
    position: absolute;
    left: auto;
    right: auto;
    top: auto;
    width: 80%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: slideUp 0.5s ease-in-out;
    border-radius: 50px;
}

.joinGroupBox-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.joinGroupBox-content-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 25px;
}

.joinGroupBox-content-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-bottom: 5%;
}

.joinGroupBox-content-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-bottom: 25px;
}



.user-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
    ;
}

.user-list-item h3 {
    margin: 0;
}

.user-list-item h4 {
    margin: 0;
    margin-bottom: 2px;
}


.user-list-item p {
    margin: 0;
}

.user-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 95%;
    border-top: #a1a1a1 solid 1px;
    padding-top: 3%;
    padding-bottom: 3%;
}

.user-list-item-top {
    display: flex;
    justify-content: space-between;
    align-items: right;
    flex-direction: row;
    width: 100%;
}

.user-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1%;
}

.user-list-claimed-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1%;
}

.user-list-item-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 28%;
    height: 100%;
}

.user-list-icon {
    height: auto;
}

.user-list-item-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 78%;
}



.user-list-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 2%;
}

.edit-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 95%;
    height: 85%;
}

.bill-item-edit {

    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 6px;
    padding-bottom: 6px;
}


.bill-item-edit-Left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 75%;
    height: 100%;
    padding-left: 10px;
}

.bill-item-edit-Right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.bill-item-edit h3 {
    margin: 0px;
    font-size: 20px;

}

.bill-item-edit h4 {
    margin: 0px;
    font-size: 17px;
    color: rgb(95, 95, 95);
}

.bill-item-edit p {
    margin: 0px;
    font-size: 15px;
    color: rgb(172, 172, 172);
}

.bill-item-edit button {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 2.5px;
    margin-top: 2.5px;
    border-radius: 15px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bill-item-edit-Submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;
}

.billImageCropperEditItemName {
    width: 100%;
}

.nameAndTotalPrice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.nameAndTotalPrice1 {
    width: 82%;

}

.nameAndTotalPrice2 {
    width: 5%;
}

.nameAndTotalPrice3 {
    width: 13%;
    text-align: right;
}

.bill-item-edit-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 50%;
}

.bill-item-edit-price {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 4px;
    height: 50%;
}

.leave-room {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 6%;
    text-align: center;
}

.leave-room h3 {
    margin: 0;
    margin-bottom: 4%;
}

.service-Charge-Middle {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

}

.birthday-users {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 95%;
    margin-bottom: 2%;
    overflow: scroll;
}

.birthday-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid gray;
    padding-top: 6px;
    padding-bottom: 6px;
}

.birthday-user-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 25%;
}

.birthday-user-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 50%;
}

.birthday-user-button-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 30%;
}

.add-user-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.OptionBox {
    height: 100%;
    padding-bottom: 10px;
}

.select-user-list-popup {
    position: absolute;
    width: 90%;
    height: 90%;
    border-radius: 50px;
    background-color: white;
    border: #1A1C33 solid 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 7.5%;
    left: 5%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.select-user-list-popup.visible {
    opacity: 1;
}

.select-user-list-popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 85%;
    border-bottom: 1px solid gray;
    margin-top: 1%;
    height: 10%;
}

.select-user-list-popup-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 95%;
    overflow: scroll;
    height: 77%;
}

.select-user-list-popup-button-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 85%;
    height: 13%;
    border-top: 1px solid gray;
}

.select-user-list-popup-button {
    width: 100%;
}

.select-user-list-popup-user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 85%;
    border-top: 1px solid gray;
    padding-top: 6px;
    padding-bottom: 6px;
}

.select-user-list-popup-user-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 26%;
}

.select-user-list-popup-user-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 74%;
}

.your-total-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 90%;
    position: absolute;
    bottom: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: rgb(234, 253, 234);
    animation: slideUp 0.5s ease-in-out;
}

.your-total-details-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 90%;
    height: 10%;
    border-bottom: 1px solid gray;
}

.your-total-details-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: 80%;
    overflow: scroll;
}


.provsionalItemsButtons {
    height: 30px;
    width: auto;
}


.your-total-details-total {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80%;
    height: 10%;
    border-top: 1px solid gray;
}

.your-total-details h2 {
    margin: 0px;
}

.your-total-details h3 {
    margin: 0px;
}

.your-total-details h4 {
    margin: 0px;
}

.tascInputsContainer{
    height: auto;
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

.your-total-details h5 {
    margin: 0px;
}

.your-total-details p {
    margin: 0px;
}

.group-title-section {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.group-main-section {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    
}

.bill-Items-Information-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 12%;
    width: 100%;
    max-height: 90px;
}

.user-list-claimed-bill-items {
    width: 100%;
}

.addItems {
    width: 90%;
    padding-bottom: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.addItemsTop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
}

.addItemsMid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.unconfirmedIndicator {
    margin: 0px;
    color: white;
    background-color: lightcoral;
    border-radius: 25px;
    padding: 5px 10px;
    text-align: left;
    display: inline-block;
}


.confirmedIndicator {
    margin: 0px;
    color: white;
    background-color: lightgreen;
    border-radius: 25px;
    padding: 5px 10px;
    text-align: left;
    display: inline-block;
}