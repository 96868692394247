.owed-to-you-container{
    position: absolute;
    width: 95%;
    height: 97%;
    background-color: white;
    z-index: 10;
    border-radius: 50px;
    top: 1%;
    animation: slideUp 0.5s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    padding-top: 10px;
}

.owed-to-you-container p{
    z-index: 50;
    margin: 0 0 0 0;
    text-align: center;

}

.owed-to-you-container h1{
    z-index: 50;
    margin: 0 0 0 0;
    text-align: center;
    font-size: 75px;
}

.owed-to-you-container h2{
    z-index: 50;
    margin: 0 0 0 0;
    font-size: 35px;
    text-align: center;
}

.owed-to-you-container h3{
    z-index: 50;
    margin: 0 0 0 0; 
    font-size: 25px;
    text-align: center;
}

.owed-to-you-container h5{
    z-index: 50;
    margin: 0 0 0 0;
    text-align: center;
}

.owed-to-you-container button{
    z-index: 50;
    margin: 0 0 0 0;

}

.owed-to-you-top{
    z-index: 50;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 7%;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
}

.owed-to-you-mid{
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 78%;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
}

.owed-to-you-bottom{
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 15%;

    
}

.user-owes-list-item-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 80%;

}


@media (max-width:  388px) {
    .owed-to-you-container h2{
        font-size: 30px;
    }
    
    .owed-to-you-container h3{

        font-size: 20px;

    }
}

@media (max-height:  650px) {
    .owed-to-you-container h2{
        font-size: 30px;
    }
    
    .owed-to-you-container h3{

        font-size: 20px;

    }
}


@media (max-height: 450px) {
    .owed-to-you-container h2{
        font-size: 25px;
    }
    
    .owed-to-you-container h3{

        font-size: 17px;

    }

    .owed-to-you-container h1{
        font-size: 55px;
    }
}

@media (max-width:  325px) {
    .owed-to-you-container h2{
        font-size: 28px;
    }
    
    .owed-to-you-container h3{

        font-size: 20px;

    }

    .owed-to-you-container h1{
        font-size: 55px;
    }
}

@media (max-width:  308px) {
    .owed-to-you-container h2{
        font-size: 24px;
    }
    
    .owed-to-you-container h3{

        font-size: 16px;

    }

    .owed-to-you-container h1{
        font-size: 50px;
    }
}