.ClaimeMoreItems-Container {
    position: absolute;
    width: 95%;
    height: 97%;
    border-radius: 50px;
    background-color: white;
    border: #1A1C33 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 1%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
}

.ClaimeMoreItems-Container.visible {
    opacity: 1;
}

.ClaimeMoreItemsTitle{
    height: 5%;
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}