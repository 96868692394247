@keyframes slide-in {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}
 
.slide-in {
    animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
}

.birthdayNotifText{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
