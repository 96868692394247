body {
    display: block;
    overflow: hidden;
    touch-action: manipulation; 
    background: #98E3F4;
    -webkit-overflow-scrolling: touch;
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
}

input {
    box-sizing: border-box;
    padding: 10px;
    font-size: 20px;
    border-radius: 25px;
    border: solid 2px #1A1C33;
}

button {
    background-color: #1A1C33;
    color: white;
    border-radius: 25px;
    padding: 10px;
    font-size: 20px;
    border: none;
}

@media (max-height: 500px) {
    html {
      font-size: 80%;  /* Decrease the font size to 80% of the original */
    }


    input {
        padding: 7px;
        font-size: 17px;
    }
    
    button {
        padding: 7px;
        font-size: 17px;
    }


}

@media (max-width: 400px) {
    html {
      font-size: 90%;  /* Decrease the font size to 80% of the original */
    }


    input {
        padding: 7px;
        font-size: 17px;
    }
    
    button {
        padding: 7px;
        font-size: 17px;
    }


}

@media (max-width: 350px) {
    html {
      font-size: 80%;  /* Decrease the font size to 80% of the original */
    }


    input {
        padding: 7px;
        font-size: 17px;
    }
    
    button {
        padding: 7px;
        font-size: 17px;
    }


}

@media (max-width: 300px) {
    html {
      font-size: 70%;  /* Decrease the font size to 70% of the original */
    
    }

    input {
        font-size: 14px;
    }
    
    button {
        font-size: 14px;
    }

    .homeButton{
        padding: 0px;
        font-size: 20px;
    }

}
  

.qrCode{
    border: #1A1C33 outset 10px;
    border-radius: 25px;
    margin-top: 5%;
    padding-Top: 3%;
    padding-bottom: 3%;
    width: 90%;
}

.qrCodeParent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    border: #1A1C33 solid 10px;
    border-radius: 25px;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 5%;
    margin-bottom: 5%;
}


.socialMedia{
    margin: 0.1%;
}

.socialMediaIcon{
    width: 90%;
    height: auto;
}


ul {
    padding: 0px;
    margin: 0px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.backButton{
    background-color: white;
    border-radius: 50%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    aspect-ratio: 1/1;

}

.optionsButton{
    background-color: white;
    border-radius: 50%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    aspect-ratio: 1/1;


}

.optionsImage{
    width: 20px;
    height: auto;
}

.optionsCreateGroup{
    position: absolute;
    width: 90%;
    height: 97%;
    border-radius: 50px;
    background-color: white;
    border: #1A1C33 solid 1px;
    display: flex;
    top: 1%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
}

.optionsCreateGroup.visible {
    opacity: 1;
}

.optionsGroup{
    position: absolute;
    width: 95%;
    height: 97%;
    border-radius: 50px;
    background-color: white;
    border: #1A1C33 solid 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 1%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 200;
}

.optionsGroup.visible {
    opacity: 1;
}



.optionsTitle{
    height: 5%;
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.optionsContent{
    height: 85%;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: scroll
}

.option{
    border-bottom: 1px solid gray;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-height: 85%;

}

.provisionalBillItemsContent{
    height: 77%;
    width: 85%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
    overflow: scroll;
    
}

.provisionalBillItem{
    border-bottom: 1px solid gray;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

.provisionalBillItemLeft{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 75%;
    padding-left: 5px;
}

.provisionalBillItemRight{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 15%;
    padding-right: 5px
}

.provisionalBillItem h3 {
    margin: 0px;
    font-size: 20px;
    
}

.provisionalBillItem h4 {
    margin: 0px;
    font-size: 17px;
    color: rgb(95, 95, 95);
}

.provisionalBillItem p {
    margin: 0px;
    font-size: 15px;
    color: rgb(172, 172, 172);
}

.provisionalBillItem button{
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 2.5px;
    margin-top: 2.5px;
    border-radius: 15px;
    width: 45px;
    height: 45px;
}


.provisionalBillItemQuantity{
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 4px;
}


.closeButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-right: 25px;
    margin-top: 3px;
    background-color: transparent;
}


.closeButtonImage{
    width: 10px;
    height: auto;
}

.closeButtonDiv{
    display: flex;
    justify-content: right;
    align-items: center;
}

.backArrowImage{
    width: 20px;
    height: auto;
}

.createGroupDiv{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.exitButton{
    border-radius: 50%;
    font-size: 15px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 8px;
}

.item-button {
    border-radius: 25px;
    width: 45%;
    height: 40px;
    padding: 2px;
    font-size: 20px;
    margin-bottom: 12px;
}

.item-button-sumbit {
    border-radius: 25px;
    width: 100%;
    padding: 2px;
    font-size: 20px;
}


.item-name-edit {
    width: 70%;
    margin-right: 10%;
}

.item-price-edit {
    width: 20%;
}

.spinner {
    top: 50%;
    left: 50%;
}

.disabled {
    opacity: 0.5; /* Apply 50% opacity to the button */
    cursor: not-allowed; /* Change cursor to not-allowed */
}


.userPicture{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 3.5%;
}
  
.divForHostUserImageCameraButton{
    position: absolute;
    left: 75%;
    top: 12%;
}

.disabledBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #000000;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.5s;
}

.greyedOutvisible {
    opacity: 0.8;
    transition: opacity 0.5s;
}



.fade {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}
option
.fade-in {
    animation-name: fadeIn;
    animation-duration: 0.5s; /* adjust as needed */
    animation-fill-mode: forwards;
  }
  
  .fade-out {
    animation-name: fadeOut;
    animation-duration: 0.5s; /* adjust as needed */
    animation-fill-mode: forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }


.your-total-button{
    background-color: #90ee90;
    width: 100%;
    height: 80%;
    border-radius: 40px;
    padding: 0% 10% 0% 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bill-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 1;
    justify-content: space-between;
}

.main{
    background: #98E3F4;
    /* background: linear-gradient(47deg, rgba(203,94,238,1) 0%, rgba(75,225,236,1) 100%); */
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home{
    width: 100vw;
    height: 100vh;  max-height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}





.goToGroupBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 20px;
    animation: slideUp 0.5s ease-in-out;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.goToGroupBox-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.goToGroupBox-content-top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
}

.errorMessage{
    color: red;
    font-size: 20px;
    text-align: center;
}


.divForUserImageCameraButton{
    position: absolute;
    left: 75%;
    top: 25%;
}
.userImageCameraButton{
    margin-left: 20px;
}

.avatarEditor{
    border-radius: 25px;
}


.takeUserImageButton{
    background-color: #1A1C33;
    color: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;

}

.userImageCropperImg{
    margin-top: 40px;
}

.userImageCropperButtons{
    display: flex;
    justify-content: space-between;
    width: 80%;
    
}
.userImageCropper{
    background-color: bisque;
    position: absolute;
    top: -64%;
    left: 12.5%;
    width: 75%;
    height: 120%;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}
.userProfileImageCropper{
    background-color: bisque;
    position: absolute;
    top: 3%;
    left: 10%;
    width: 80%;
    height: 55%;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

input[type="file"] {
    display: none;
}

.user-picture-circle {
    border-radius: 100%;
    background-color: pink;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    font-size: 60px;
    color: white;
    aspect-ratio: 1 / 1;
}

.goToGroupBox-content-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.goToGroupBox-content-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@keyframes slideUp {
    from {
        bottom: -100%;
    }
    to {
        bottom: 0;
    }
}

.claimeesUL{
    display: flex;
    justify-content: left;
    align-items: center;
    width: 70%;
    height: 100%;
    flex-direction: row;
    margin: 0px;
    overflow: scroll;
}

.claimeeIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.claimeeIconImage{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
    border: #1A1C33 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    padding: 0px;
}

.birthdayNotif{
    position: absolute;
    top: 1%;
    width: 95%;
    height: 16%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: #1A1C33 solid 1px;


    border-radius: 25px;
    z-index: 1000 ;
    background-color: white;
}


.changeApplied{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    border-top: #1A1C33 solid 1px;
    border-left: #1A1C33 solid 1px;
    border-right: #1A1C33 solid 1px;
    border-radius: 50px;
    z-index: 10;
    background-color: white;
}

.closeBrithdayNotif{
    position: absolute;
    top: 6%;
    right: 1%;
}

.changeAppliedText{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.changeAppliedText h2{
    margin: 0;
}

.authButton{
    background-color: transparent;
    border: none;
    color: darkgrey;
}



@media (max-height: 650px) {
    .backButton{
        width: 40px;
        aspect-ratio: 1/1;
    }
    
    .optionsButton{

        width: 40px;
        aspect-ratio: 1/1;
    
    }
}

@media (max-height: 475px) {
    .backButton{
        width: 35px;
        aspect-ratio: 1/1;
    }
    
    .optionsButton{

        width: 35px;
        aspect-ratio: 1/1;
    
    }
}

@media (max-height: 425px) {
    .backButton{
        width: 30px;
        aspect-ratio: 1/1;
    }
    
    .optionsButton{

        width: 30px;
        aspect-ratio: 1/1;
    
    }
}

